<template>
	<div>

	</div>
</template>
<style>

</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
              symbolPrice: {}
            }
        },
        created(){

        },
        mounted(){

        },
        computed: {
            ...mapGetters([])
        },
        watch:{

        },
        methods: {
          // 获取kraken市场价
          getTicker(){
            if(this.$route.path.indexOf('/swap/usdt/kraken') > -1){
              let current = {
                  'price': 0,
                  'color': ''
              };
              let bid = {
                  'price': 0,
                  'color': ''
              };
              let ask = {
                  'price': 0,
                  'color': ''
              };
              this.symbolPrice[symbol] = {
                  'current': current,
                  'bid': bid,
                  'ask': ask
              }
            }
            this.$store.dispatch('marketPriceHandler', this.symbolPrice);
          }
        },
        beforeDestroy() {

        }
    }
</script>
